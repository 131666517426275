
import Vue from 'vue';
import Tabs from '@core/components/UI/tabs/Tabs.vue';
import Tab from '@core/components/UI/tabs/Tab.vue';
import Modal from '@core/components/UI/Modal.vue';
import CookieConsentPrivacy from './CookieConsentPrivacy.vue';
import CookieConsentLegitimate from './CookieConsentLegitimate.vue';
import { GVL, TCString, VendorList } from '@iabtechlabtcf/core';
import { CmpApi } from '@iabtechlabtcf/cmpapi';
import {
  consentDefault,
  localConsentCookieName,
  euConsentCookieName,
  cmpData,
  nonEuConsentCookieName,
} from '@root/modules/analytics/config/cookie-consent/cookieConsent.config';
import {
  getConsentCookies,
  setConsentCookies,
  createConsentString,
  createNonTcfConsentString,
  getGroupsConsent,
} from '@root/modules/analytics/utils/cookieConsent';
import { CookieConsentGroup, LegitimateInterest, CookieConsentCookieValue, CookieConsentNonTcfVendor } from '@root/modules/analytics/types/analytics';
import { fetchConsentData } from '@root/modules/analytics/services/fetchConsentData.service';
import { ConsentCookieValue } from '@root/modules/analytics/types/CookieConsent';

import { fetchNonTcfVendorsListUrl } from '@root/modules/analytics/services/fetchConsentData.service';

interface Data {
  showBaseModal: boolean;
  groups: CookieConsentGroup[];
  gvl: GVL | undefined;
  cmpApi: CmpApi | null;
  legitimateInterest: LegitimateInterest;
  consentVendors: number[];
  vendorsListShow: boolean;
  nonTcfVendors: CookieConsentNonTcfVendor[];
  consentNonTcfVendors: number[];
  disableConsentModal: boolean;
}

interface Computed {
  showConsent: boolean;
  vendorList: VendorList | undefined;
}

interface Methods {
  fetchConsentData: () => Promise<void>;
  prepareConsentData: () => Promise<void>;
  openVendorsSettings: () => void;
  openConsentSettings: () => void;
  closeConsentModal: () => void;
  proceedUserConsent: () => void;
  initConsentGroups: (consentCookie: ConsentCookieValue, consentString: string) => void;
  updateGroups: (group: CookieConsentGroup[]) => void;
  updateLegitimateInterestVendors: (vendors: number[]) => void;
  updateLegitimateInterestPurposes: (purposes: number[]) => void;
  updateConsentVendors: (vendorIds: number[]) => void;
  updateNonTcfConsentVendors: (vendors: number[]) => void;
  consentToAll: () => void;
}

interface TabsRef extends Vue {
  changeTab: (tab: Vue | Element | Vue[] | Element[]) => void;
}

export default Vue.extend<Data, Methods, Computed, unknown>({
  components: {
    Modal,
    Tabs,
    Tab,
    CookieConsentPrivacy,
    CookieConsentLegitimate,
  },
  data() {
    return {
      vendorsListShow: false,
      disableConsentModal: false,
      showBaseModal: false,
      gvl: undefined,
      cmpApi: null,
      groups: consentDefault,
      consentVendors: [],
      legitimateInterest: {
        vendors: [],
        purposes: [],
      },
      nonTcfVendors: [],
      consentNonTcfVendors: [],
    };
  },
  computed: {
    showConsent() {
      return this.$store.getters['analytics/getConsentSettingsVisibility'] || false;
    },
    vendorList() {
      return this.$store.getters['analytics/getVendorsList'];
    },
  },
  watch: {
    async showConsent(show) {
      if (show) {
        await this.prepareConsentData();
      }
    },
  },
  async mounted() {
    const { specialFeatures } = this.$channelConfig('settings').application;
    this.disableConsentModal = specialFeatures.disableConsentModal || false;
    if (this.disableConsentModal) {
      return false;
    }

    try {
      const fetchSSRCookie = this.$store.state.analytics.fetchSSRCookies;
      const { consent: consentCookie } = await getConsentCookies([localConsentCookieName], fetchSSRCookie);

      await this.prepareConsentData();

      this.nonTcfVendors = await fetchNonTcfVendorsListUrl();

      if (!consentCookie) {
        this.showBaseModal = true;
      }
    } catch (error) {
      this.$sentry.captureException(error, {
        tags: { cookieConsent: 'cookiesFetchFailedOnConsentModal', 'process.type': 'client' },
      });
    }
  },
  methods: {
    // TODO: as cookie data needs to be loaded on every page load
    // perhaps this method can be split into loading cookie data and loading data that is required to show the modal
    async prepareConsentData() {
      const fetchSSRCookie = this.$store.state.analytics.fetchSSRCookies;
      const {
        consent: consentCookie,
        'em-euconsent-v2': consentString,
        addtl_consent: consentNonTcfString,
      } = await getConsentCookies([localConsentCookieName, euConsentCookieName, nonEuConsentCookieName], fetchSSRCookie);

      await this.fetchConsentData();
      // TODO: this seems to be used only for encoding cookie?
      //       perhaps we could optimize loading this only when writing new cookie
      this.gvl = new GVL(this.vendorList);
      // https://www.npmjs.com/package/@iabtechlabtcf/cmpapi#create-cmpapi
      // During construction of the CmpApi,
      // the window._tcfapi stub is replaced with CmpApi's own function
      // for handling window._tcfapi command requests.
      // _tcfapi should always be on page as it is used by partners (at least AdNet) to know user(s) consent(s)
      this.cmpApi = new CmpApi(cmpData.id, cmpData.version, true);
      this.cmpApi.update(consentCookie ? consentString : '', !consentCookie);

      if (consentCookie && consentString) {
        this.initConsentGroups(consentCookie as unknown as CookieConsentCookieValue, consentString);
      }

      if (consentNonTcfString) {
        const nonTfcVendors = consentNonTcfString.split('~')[1] || '';
        this.consentNonTcfVendors = nonTfcVendors ? nonTfcVendors.split('.').map(Number) : [];
      }
    },
    async fetchConsentData() {
      if (this.vendorList) {
        return;
      }

      const { lang } = this.$channelConfig('settings');
      const vendors = await fetchConsentData(lang);

      this.$store.commit('analytics/setVendorsList', vendors);
    },
    initConsentGroups(consentCookie, consentString) {
      const decodedString = TCString.decode(consentString);

      this.legitimateInterest.vendors = [...decodedString.vendorLegitimateInterests.values()];
      this.consentVendors = [...decodedString.vendorConsents.values()];
      this.legitimateInterest.purposes = [...decodedString.purposeLegitimateInterests.values()];
      this.groups.forEach((group) => {
        group.selected = consentCookie[group.type as keyof CookieConsentCookieValue].consent || false;
      });

      if (decodedString.policyVersion === 2 && this.gvl && this.cmpApi) {
        const encodedTCString = createConsentString(this.gvl, this.groups, this.legitimateInterest, this.consentVendors);
        const cookieReSet = {
          [euConsentCookieName]: encodedTCString,
        };
        setConsentCookies(cookieReSet);
        this.cmpApi.update(encodedTCString, false);
      }

      if (consentCookie?.ads?.consent && !decodedString.purposeConsents.has(1) && this.gvl && this.cmpApi) {
        // re-create encoded string if it's missing the required purpose
        // see https://gitlab.delfi.net/home/issues/-/issues/3424
        // can be removed after 2023-01-05 ( as cookies without purposeConsents 1 would be expired )
        const encodedTCString = createConsentString(this.gvl, this.groups, this.legitimateInterest, this.consentVendors);
        const cookieReSet = {
          [euConsentCookieName]: encodedTCString,
        };
        setConsentCookies(cookieReSet);
        this.cmpApi.update(encodedTCString, false);
      }
    },
    closeConsentModal() {
      this.showBaseModal = false;
      this.$store.commit('analytics/setConsentSettingsVisibility', false);
    },
    openConsentSettings() {
      this.$nextTick(() => {
        const tabs = this.$refs.tabs as TabsRef;
        tabs.changeTab(this.$refs.settingsTab as HTMLElement);
      });
      this.$store.commit('analytics/setConsentSettingsVisibility', true);
    },
    openVendorsSettings() {
      this.openConsentSettings();
      this.vendorsListShow = true;
    },
    proceedUserConsent() {
      if (!this.gvl) {
        return false;
      }

      // Store user groups consent to cookie
      const consentData: Record<string, unknown> = {};
      this.groups.forEach((group) => {
        consentData[group.type] = {
          consent: group.selected,
        };
      });
      const encodedTCString = createConsentString(this.gvl, this.groups, this.legitimateInterest, this.consentVendors);
      const nonTcfCookieValue = createNonTcfConsentString(this.consentNonTcfVendors);
      // Store consent cookies
      const cookiesToSet = {
        [localConsentCookieName]: JSON.stringify(consentData),
        [euConsentCookieName]: encodedTCString,
        [nonEuConsentCookieName]: nonTcfCookieValue,
      };
      setConsentCookies(cookiesToSet);

      if (this.cmpApi) {
        this.cmpApi.update(encodedTCString, false);
      }
      this.closeConsentModal();
    },
    consentToAll() {
      this.groups.forEach((group) => (group.selected = true));

      if (this.vendorList && this.vendorList.purposes) {
        const legitimatePurposes: number[] = [];
        Object.keys(this.vendorList.purposes).forEach((key) => {
          this.vendorList?.purposes[key] && legitimatePurposes.push(this.vendorList.purposes[key].id);
        });
        this.legitimateInterest.purposes = legitimatePurposes;
      }

      if (this.vendorList?.vendors) {
        const vendorsPurposes: number[] = [];
        Object.keys(this.vendorList.vendors).forEach((key) => {
          this.vendorList?.vendors[key] && vendorsPurposes.push(this.vendorList.vendors[key].id);
        });
        this.legitimateInterest.vendors = vendorsPurposes;
      }

      this.consentNonTcfVendors = [];
      this.nonTcfVendors.forEach(({ provider_id }) => {
        this.consentNonTcfVendors.push(Number(provider_id));
      });
    },
    updateGroups(groups) {
      this.groups = groups;

      if (this.gvl) {
        const { purposes } = getGroupsConsent(this.gvl, groups);
        this.legitimateInterest.purposes = purposes;
      }
    },
    updateLegitimateInterestVendors(vendors) {
      this.legitimateInterest.vendors = vendors;
    },
    updateLegitimateInterestPurposes(purposes) {
      this.legitimateInterest.purposes = purposes;
    },
    updateConsentVendors(vendorIds) {
      this.consentVendors = vendorIds;
    },
    updateNonTcfConsentVendors(vendors) {
      this.consentNonTcfVendors = vendors;
    },
  },
});
