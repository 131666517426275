import { OfferConfiguration } from '@piano/types/pianoState';
export const getOfferConfiguration = ({
  hasRecentlyEndedSubscription = false,
  upgradeSubscription = false,
  offersConfiguration,
}: {
  hasRecentlyEndedSubscription?: boolean;
  upgradeSubscription?: boolean;
  offersConfiguration: Record<string, OfferConfiguration>;
}): OfferConfiguration | undefined => {
  const returningCustomerOffer = offersConfiguration['returningCustomer'];
  const upgradeOffer = offersConfiguration['upgrade'];
  const defaultCustomerOffer = offersConfiguration['default'];

  if (hasRecentlyEndedSubscription && returningCustomerOffer) {
    return returningCustomerOffer;
  }

  if (upgradeSubscription && upgradeOffer) {
    return upgradeOffer;
  }

  return defaultCustomerOffer;
};
